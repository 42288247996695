// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.BILL_header]: "Welcome",
  [keys.BILL_balance]: "Balance",
  [keys.BILL_addFunds]: "Add funds",
  [keys.BILL_addFundsPopup]: "Add funds for template messages",
  [keys.BILL_addedFundsDescription]:
    "Added funds will only count towards template message usage. Monthly or yearly plans for WhatsApp Business API Accounts will be invoiced and billed separately.",
  [keys.BILL_addFundsPopupQuestion]:
    "How many template are you planning to send per month?",
  [keys.BILL_addFundsPopupTooltip]:
    "Based on your planned template usage we can estimate the total costs of your templated messages per billing period. However, template message pricing varies by country and therefore the costs per message can only be estimated.",
  [keys.BILL_costBreakdown]: "Cost breakdown",
  [keys.BILL_estTemplateCost]: "Estimated template messages cost",
  [keys.BILL_transFees]: "Transaction fees",
  [keys.BILL_subtotal]: "Subtotal",
  [keys.BILL_subAndPay]: "Submit and pay now",
  [keys.BILL_monthlyUsage]: "Monthly usage",
  [keys.BILL_numTemplatesSent]: "Number of templates sent",
  [keys.BILL_costsByTemplates]: "Costs caused by templates",
  [keys.BILL_noData]: "No data for this report",
  [keys.BILL_filterByNum]: "Filter by number",
  [keys.BILL_selectAll]: "Select all",
  [keys.BILL_numberNotLive]: "This number is not live yet",
  [keys.BILL_noBillingCountry]: "You have to set billing country!",
  [keys.BILL_amountDue]: "Amount due",
  [keys.BILL_notApplicable]: "N/A",
  [keys.BILL_vat]: "VAT",
  [keys.BILL_addFundsSectionMsg]:
    "The selected amount above will be charged and counted towards your current balance for WhatsApp costs. It will be a one time charge and will not affect your auto-renewal settings. Also please be aware that the monthly subscription fees are charged separately and does not use the prepaid balance.",
  [keys.BILL_autoRenewalSuccess]:
    "Auto-renewal successfully enabled. Threshold and amount can be set manually.",
  [keys.BILL_thresholdChangeSuccess]:
    "Auto renewal threshold changed successfully",
  [keys.BILL_amountChangeSuccess]: "Auto renewal amount changed successfully",
  [keys.BILL_renewalHeading]: "Manage numbers",
  [keys.BILL_renewalTooltip]:
    "If the balance falls below this amount, the balance gets renewed with the auto-renewal amount.",
  [keys.BILL_thresholdSection]: "Threshold for auto-renewal",
  [keys.BILL_excludingTransactionFees]: "excluding transaction fees and VAT",
  [keys.BILL_autoRenewal]: "Auto-renewal",
  [keys.BILL_autoRenewalAmount]: "Auto-renewal amount",
  [keys.BILL_oneOffCharge]: "One-off charge",
  [keys.BILL_changeSettings]: "Change settings",
  [keys.BILL_renewalPopupHeading]: "Change auto-renewal amount",
  [keys.BILL_renewalPopupSectionMsg]:
    "This changes the settings for your auto-renewal. The selected amount will be charged the next time your balance falls below the threshold value. You won't be charged right now if your balance is still above the threshold.",
  [keys.BILL_submitChanges]: "Submit changes",
  [keys.BILL_balanceBreakDown]: "Balance breakdown",
  [keys.BILL_selectNumberAction]: "Select number and action",
  [keys.BILL_estimateCosts]: "Estimate costs",
  [keys.BILL_confirmCheckout]: "Confirm checkout",
  [keys.BILL_selectAction]: "Select which action you want to perform",
  [keys.BILL_addOneOffFunds]: "Add funds to this number with a one-off charge",
  [keys.BILL_addAutorenewalSettings]:
    "Change auto-renewal settings for this number",
  [keys.BILL_autoRenewalSectionMsg]:
    "By submitting the changes you continue to change the settings for your auto-renewal. The selected amount will be charged the next time your balance falls below the threshold value. You won't be charged right now if your balance is still above the threshold. Also please be aware that the monthly subscription fees are charged separately and do not use the prepaid balance.",
  [keys.BILL_changeAutoRenewalThreshold]: "Change auto-renewal threshold",
  [keys.BILL_thresholdAmount]: "Auto-renewal threshold amount",
  [keys.BILL_thresholdRenewalMsg]:
    "If the balance for the selected number falls below the auto-renewal threshold amount, the balance gets renewed with the auto-renewal amount, which can be specified separately.",
  [keys.BILL_processingPayment]:
    "Payment is being processed. Balance will be updated shortly.",
  [keys.BILL_businessInitiatedQuestion]:
    "How many business initiated conversations are you planning to create?",
  [keys.BILL_userInitiatedQuestion]:
    "How many user initiated conversations will you likely have?",
  [keys.BILL_estimatedConversationCost]: "Estimated conversation costs",
  [keys.BILL_previousMonth]: "previous month",
  [keys.BILL_addFundsNotAllowed]: "Cannot add funds to draft numbers",
  [keys.BILL_othersCombined]: "Others (combined)",
  [keys.BILL_approxCharges]: "Approximate charges",
  [keys.BILL_allConversations]: "All conversations",
  [keys.BILL_insights]: "Insights",
  [keys.BILL_insightsAndBilling]: "Insights & Billing",
  [keys.BILL_businessInitiatedAmountTooltip]:
    "Based on the amount of planned business initiated conversations we can estimate the conversation costs that you will probably generate. However, conversation pricing varies by country and therefore the costs per message can only be estimated. These estimations are based on the assumption, that you will only initiate conversations within the country of your registered phone number’s origin.",
  [keys.BILL_userInitiatedAmountTooltip]:
    "Based on the amount of planned user initiated conversations we can estimate the conversation costs that you will probably generate. However, conversation pricing varies by country and therefore the costs per message can only be estimated. These estimations are based on the assumption, that you will only receive user initiated conversations within the country of your registered phone number’s origin.",
  [keys.BILL_costBreakdownTooltip]:
    "Based on the amount of planned user and business initiated conversations we can estimate the conversation costs that you will probably generate. However, conversation pricing varies by country and therefore the costs per message can only be estimated. These estimations are based on the assumption, that you will only have conversations within the country of your registered phone number’s origin.",
  [keys.BILL_customize]: "Customize",
  [keys.BILL_noCharges]:
    "No charges will be made, estimated conversations falls within your available monthly free conversation (1000)",
  [keys.BILL_fundsWereAdded]: "Funds were added successfully",
  [keys.BILL_enabled]: "enabled",
  [keys.BILL_disabled]: "disabled",
  [keys.BILL_vat2]: "Add the funds",
  [keys.BILL_zeroBalanceText_one]:
    "The balance for the number {{numbers}} is 0. You are no longer able to send messages within a paid conversation. Please add funds if you want to continue using this number within a paid conversation.",
  [keys.BILL_zeroBalanceText_other]:
    "The balance for these numbers {{numbers}} are 0. You are no longer able to send messages within a paid conversation. Please add funds if you want to continue using these numbers within a paid conversation.",
  [keys.BILL_lowBalanceText_one]:
    "The balance for the number {{numbers}} is low. In order to keep engaging in paid conversations, please add funds manually or enable auto-renewal. If the balance of this number reaches 0, you will no longer be able to send messages within a paid conversation.",
  [keys.BILL_lowBalanceText_other]:
    "The balance for these numbers {{numbers}} are low. In order to keep engaging in paid conversations, please add funds manually or enable auto-renewal. If the balance of these numbers reaches 0, you will no longer be able to send messages within a paid conversation.",
  [keys.BILL_automatic]: "Automatic",
  [keys.BILL_manageFunds]: "Manage Funds",
  [keys.BILL_refund]: "Refund",
  [keys.BILL_refundAccountTitle]: "Refund balance from this number",
  [keys.BILL_refundingDetails]: "Refunding details",
  [keys.BILL_channelFunds]: "Channel funds",
  [keys.BILL_noPendingBalance]: "No pending balance to refund",
  [keys.BILL_totalAmountToRefund]: "Total ammount to refund",
  [keys.BILL_refundInfomationText]:
    "Your funds will be refunded through your default payment method.",
  [keys.BILL_refundInformationText2]:
    "If you haven’t engaged in any paid conversation after your last balance top up and therefore haven’t spent any of your balance, the refund will also include the paid payment processing fee. Otherwise you will receive a refund of your current remaining balance without any paid processing fees.",
  [keys.BILL_fundsBalance]: "Funds Balance",
  [keys.BILL_automaticAutoRenewal]: "Smart Auto-Renewal",
  [keys.BILL_descriptionText1]:
    "We are happy that you are jumping in with our new feature!",
  [keys.BILL_descriptionText2]:
    "We created automatic auto-renewal to help you be as precise as possible with your fundings.",
  [keys.BILL_descriptionText3]:
    "Based on your previous month(s) conversations and  trends in costs, we are setting up your number's threshold and amount to:",
  [keys.BILL_threshold]: "Threshold",
  [keys.BILL_autoMaticSettingMessage]:
    "Please note that these settings could vary in order to always adjust to your numbers funding needs.",
  [keys.BILL_setAutomaticRenewal]: "Set automatic auto-renewal",
  [keys.BILL_automaticTooltip]:
    "Automatic auto-renewal sets your treshold and amount automatically based on your previous month(s) conversations and  trends in costs. We recommend to have it active to never run out of funds and to also have an accurate number funding.",
  [keys.BILL_enableAutoSettingsMessage]:
    "Smart auto-renewal successfully enabled. Threshold and amount will be calculated automatically.",
  [keys.BILL_disableAutoSettingsMessage]:
    "Auto-Renewal settings successfully disabled for automatic recharge",
  [keys.BILL_AutoSettingsErrorMessage]:
    "Could not change auto-renewal settings. Please try again.",
  [keys.BILL_refundSuccessNotification]:
    "Your refund has been initiated successfully! Your balance will be updated accordingly shortly.",
  [keys.BILL_autoRenewalDisabled]: "Auto-renewal successfully turned off",
  [keys.BILL_offOptionTooltip]:
    "Your funds will not be automatically renewed. You have to add funds manually whenever your balance runs low in order to continue to engage in paid conversations. We recommend enabling auto-renewal to never run out of funds.",
  [keys.BILL_manualOptionTooltip]:
    "Auto-renewal is turned on and will automatically renew your funds based on a threshold and a renewal amount that you set manually via the options on the right.",
  [keys.BILL_smartOptionTooltip]:
    "Auto-renewal is turned on. Your threshold and amount are automatically calculated and adjusted based on your previous month(s) conversations and trends in costs",
  [keys.BILL_paymentSucceeded]: "Payment succeeded",
  [keys.BILL_paymentFailed]:
    "The provided payment method has failed authentication. Please provide new payment method to attempt to fulfill payment intent again.",
  [keys.BILL_radioDisabledTooltipText]:
    "In order to adjust your auto-renewal settings manually you need to switch auto-renewal to the “manual” mode.",
  [keys.BILL_topupDurationMessage]:
    "It can take up to 30 minutes until the balance is updated after a successful charge. To reduce the risk of being blocked from messaging we recommend enabling auto-renewal.",
  [keys.BILL_billingOptions]: "Billing options",
  [keys.BILL_fundNegativeBalance]: "Even out negative balance for this channel",
  [keys.BILL_noRefundFunds]: "No funds available for refund",
  [keys.BILL_noAddingFunds]:
    "At the moment this number is not available for adding funds",
  [keys.BILL_pendingBalanceBilling]:
    "Pending negative balance will be billed to your account.",
  [keys.BILL_refundAccount]: "Refund account funds",
  [keys.BILL_negativeBalance]: "Negative balance account!",
  [keys.BILL_negativeBalanceMessage]:
    "Some channels have negative balance, you can submit your partner change request as soon as your negative balance has been paid. Proceed to add funds to your account.",
  [keys.BILL_totalPendingCharge]: "Total Pending Charge",
  [keys.BILL_addFundsProceed]: "Proceed To Add Funds",
  [keys.BILL_clickToManageFunds]:
    "Click here to manage the funds of this number",
  [keys.BILL_billingInformation]: "Billing information",
  [keys.BILL_billingInfoName]: "Name",
  [keys.BILL_billingInfoEmail]: "Email",
  [keys.BILL_billingInfoAddress]: "Address",
  [keys.BILL_billingInfoVatId]: "VAT ID",
  [keys.BILL_billingInfoEdit]: "Edit information",
  [keys.BILL_invoices]: "Invoices",
  [keys.BILL_payInvoice]: "Pay invoice",
  [keys.BILL_invoiceViewMore]: "View more",
  [keys.BILL_cloudApiPlan]: "Cloud API plan, with dedicated support",
  [keys.BILL_partnerAccess]: "Partner access plan",
  [keys.BILL_userInitiatedConversations]:
    "User-initiated & business-initiated conversations. No markup.",
  [keys.BILL_withRegularSupport]: "with regular support",
  [keys.BILL_stripeBillingOptionsMissing]:
    "Stripe billing options is missing, Contact support for assistance",
  [keys.BILL_requestRefund]: "Request refund",
  [keys.BILL_fundsAmount]: "Funds amount",
  [keys.BILL_fundsAmountDescription]:
    "Directly select the amount of funds you'd like to add to your balance, or the number of conversations you'd like to process. The selected amount will be charged and counted towards your current balance immediately. It will be a one time charge and will not affect your auto-renewal settings.",
  [keys.BILL_estimatedConversations]: "Estimated Conversations",
  [keys.BILL_selectedFundsAmount]: "Funds amount (you selected this)",
  [keys.BILL_costBreakdownDescriptionOneTime]:
    "The selected amount will be charged and counted towards your current balance immediately. It will be a one time charge and will not affect your auto-renewal settings.",
  [keys.BILL_costBreakdownDescriptionAutoRenewal]:
    "The selected amount will be set as your new auto-renewal value. This change will take effect on your next billing cycle.",
  [keys.BILL_keepInMind]: "Keep in mind:",
  [keys.BILL_keepInMindList1]:
    "Meta provides 1,000 free service conversations per WABA per month.",
  [keys.BILL_keepInMindList2]: "You can ask for a balance refund anytime.",
  [keys.BILL_breakdown]: "Breakdown",
  [keys.BILL_provideFundsAmount]: "Provide funds amount",
  [keys.BILL_allYourNumbersWillContinue]:
    "All your numbers will continue to be billed according to our standard pricing. You can up- and downgrade your subscription plan for each individual number at any time.",
  [keys.BILL_allNumbersThatWereUpgraded]:
    "All numbers that were previously upgraded to a premium plan will continue to be charged with the premium fee.",
  [keys.BILL_total]: "Total",
  [keys.BILL_billingInformationDesc]:
    "Update your payment methods and check or download your invoices.",
  [keys.BILL_checkAndDownloadInvoices]: "Check or download your invoices.",
  [keys.BILL_noInvoiceInfo]: "Seems like you don’t have any invoices yet.",
  [keys.BILL_currentBalance]: "Current balance",
  [keys.BILL_spentLastMonth]: "Spent last month",
  [keys.BILL_fundsManager]: "Funds manager",
  [keys.BILL_autoRenewalInfo]:
    "Automated auto-renewal determines your threshold and amount based on your past month's conversations and cost trends. We highly recommend keeping this feature active to ensure you never run out of funds and maintain an accurate funding balance.",
  [keys.BILL_setAutomaticFundingRenewal]: "Set Auto Renewal Funding",
  [keys.BILL_billingInfoUpdated]: "Billing information updated",
  [keys.BILL_noBillingInfoAddedInfo]:
    "Seems like you haven’t added any billing details yet. Add billing detais to start receiving invoices.",
  [keys.BILL_addBillingDetails]: "Add billing details",
  [keys.BILL_paymentDetails]: "Payment details",
  [keys.BILL_update]: "Update",
  [keys.BILL_expire]: "Expire",
  [keys.BILL_holder]: "Holder",
  [keys.BILL_noPaymentDetailsAddedInfo]:
    "Seems like you heven’t added any payment details yet. Add payment details to start sending conversations.",
  [keys.BILL_nextBillingInfo]:
    "Billed on the first of every month. Next billing on",
  [keys.BILL_billingDetails]: "Billing details",
  [keys.BILL_billingDetailsFormDesc]: "Enter your billing details below.",
  [keys.BILL_payPendingInvoices]: "Pay pending invoices",
  [keys.BILL_updateAutoRechargeTitle]: "Update Auto-recharge settings",
  [keys.BILL_updateAutoRechargeDesc]:
    "Directly set the amount you'd like for your auto-recharge renewal, or the number of conversations you’d like to process. The selected amount will be the auto renewal amount when balance falls below the threshold value.",
  [keys.BILL_thresholdAmountErrorMessage]:
    "Threshold amount must be a number greater than 0",
  [keys.BILL_autoRenewalAmountDesc]:
    "When the balance reached the threshold, this amount will be added to your funds.",
  [keys.BILL_thresholdAmountDesc]:
    "When the balance reaches this threshold, account will be automatically recharged.",
  [keys.BILL_paymentInformation]: "Payment information",
  [keys.BILL_changeMethod]: "Change method",
  [keys.BILL_addNewPaymentMethod]: "Add new payment method",
  [keys.BILL_unpaidInvoice1]: "An invoice hasn't been paid",
  [keys.BILL_unpaidInvoice2]:
    "For your numbers to remain active, all their invoices must be paid.",
  [keys.BILL_fundsAddedSuccessfully]: "Funds added successfully",
  [keys.BILL_autoRenewalAmountDescription]:
    "Directly select the amount of funds you'd like to set for your auto-renewal setting.",
};

export default translate;
