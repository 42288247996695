// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  BILL_header: "BILL_header",
  BILL_balance: "BILL_balance",
  BILL_addFunds: "BILL_addFunds",
  BILL_addFundsPopup: "BILL_addFundsPopup",
  BILL_addedFundsDescription: "BILL_addedFundsDescription",
  BILL_addFundsPopupQuestion: "BILL_addFundsPopupQuestion",
  BILL_addFundsPopupTooltip: "BILL_addFundsPopupTooltip",
  BILL_costBreakdown: "BILL_costBreakdown",
  BILL_estTemplateCost: "BILL_estTemplateCost",
  BILL_transFees: "BILL_transFees",
  BILL_subtotal: "BILL_subtotal",
  BILL_subAndPay: "BILL_subAndPay",
  BILL_monthlyUsage: "BILL_monthlyUsage",
  BILL_numTemplatesSent: "BILL_numTemplatesSent",
  BILL_costsByTemplates: "BILL_costsByTemplates",
  BILL_noData: "BILL_noData",
  BILL_filterByNum: "BILL_filterByNum",
  BILL_selectAll: "BILL_selectAll",
  BILL_numberNotLive: "BILL_numberNotLive",
  BILL_noBillingCountry: "BILL_noBillingCountry",
  BILL_amountDue: "BILL_amountDue",
  BILL_notApplicable: "BILL_notApplicable",
  BILL_vat: "BILL_vat",
  BILL_addFundsSectionMsg: "BILL_addFundsSectionMsg",
  BILL_autoRenewalSuccess: "BILL_autoRenewalSuccess",
  BILL_thresholdChangeSuccess: "BILL_thresholdChangeSuccess",
  BILL_amountChangeSuccess: "BILL_amountChangeSuccess",
  BILL_renewalHeading: "BILL_renewalHeading",
  BILL_renewalTooltip: "BILL_renewalTooltip",
  BILL_thresholdSection: "BILL_thresholdSection",
  BILL_excludingTransactionFees: "BILL_excludingTransactionFees",
  BILL_autoRenewal: "BILL_autoRenewal",
  BILL_autoRenewalAmount: "BILL_autoRenewalAmount",
  BILL_oneOffCharge: "BILL_oneOffCharge",
  BILL_changeSettings: "BILL_changeSettings",
  BILL_renewalPopupHeading: "BILL_renewalPopupHeading",
  BILL_renewalPopupSectionMsg: "BILL_renewalPopupSectionMsg",
  BILL_submitChanges: "BILL_submitChanges",
  BILL_balanceBreakDown: "BILL_balanceBreakDown",
  BILL_selectNumberAction: "BILL_selectNumberAction",
  BILL_estimateCosts: "BILL_estimateCosts",
  BILL_confirmCheckout: "BILL_confirmCheckout",
  BILL_selectAction: "BILL_selectAction",
  BILL_addOneOffFunds: "BILL_addOneOffFunds",
  BILL_addAutorenewalSettings: "BILL_addAutorenewalSettings",
  BILL_autoRenewalSectionMsg: "BILL_autoRenewalSectionMsg",
  BILL_changeAutoRenewalThreshold: "BILL_changeAutoRenewalThreshold",
  BILL_thresholdAmount: "BILL_thresholdAmount",
  BILL_thresholdRenewalMsg: "BILL_thresholdRenewalMsg",
  BILL_processingPayment: "BILL_processingPayment",
  BILL_businessInitiatedQuestion: "BILL_businessInitiatedQuestion",
  BILL_userInitiatedQuestion: "BILL_userInitiatedQuestion",
  BILL_estimatedConversationCost: "BILL_estimatedConversationCost",
  BILL_previousMonth: "BILL_previousMonth",
  BILL_addFundsNotAllowed: "BILL_addFundsNotAllowed",
  BILL_othersCombined: "BILL_othersCombined",
  BILL_approxCharges: "BILL_approxCharges",
  BILL_allConversations: "BILL_allConversations",
  BILL_insights: "BILL_insights",
  BILL_insightsAndBilling: "BILL_insightsAndBilling",
  BILL_businessInitiatedAmountTooltip: "BILL_businessInitiatedAmountTooltip",
  BILL_userInitiatedAmountTooltip: "BILL_userInitiatedAmountTooltip",
  BILL_costBreakdownTooltip: "BILL_costBreakdownTooltip",
  BILL_customize: "BILL_customize",
  BILL_noCharges: "BILL_noCharges",
  BILL_fundsWereAdded: "BILL_fundsWereAdded",
  BILL_enabled: "BILL_enabled",
  BILL_disabled: "BILL_disabled",
  BILL_vat2: "BILL_vat2",
  BILL_zeroBalanceText_one: "BILL_zeroBalanceText_one",
  BILL_zeroBalanceText_other: "BILL_zeroBalanceText_other",
  BILL_lowBalanceText_one: "BILL_lowBalanceText_one",
  BILL_lowBalanceText_other: "BILL_lowBalanceText_other",
  BILL_automatic: "BILL_automatic",
  BILL_manageFunds: "BILL_manageFunds",
  BILL_refund: "BILL_refund",
  BILL_refundAccountTitle: "BILL_refundAccountTitle",
  BILL_refundingDetails: "BILL_refundingDetails",
  BILL_channelFunds: "BILL_channelFunds",
  BILL_noPendingBalance: "BILL_noPendingBalance",
  BILL_totalAmountToRefund: "BILL_totalAmountToRefund",
  BILL_refundInfomationText: "BILL_refundInfomationText",
  BILL_refundInformationText2: "BILL_refundInformationText2",
  BILL_fundsBalance: "BILL_fundsBalance",
  BILL_automaticAutoRenewal: "BILL_automaticAutoRenewal",
  BILL_descriptionText1: "BILL_descriptionText1",
  BILL_descriptionText2: "BILL_descriptionText2",
  BILL_descriptionText3: "BILL_descriptionText3",
  BILL_threshold: "BILL_threshold",
  BILL_autoMaticSettingMessage: "BILL_autoMaticSettingMessage",
  BILL_setAutomaticRenewal: "BILL_setAutomaticRenewal",
  BILL_automaticTooltip: "BILL_automaticTooltip",
  BILL_enableAutoSettingsMessage: "BILL_enableAutoSettingsMessage",
  BILL_disableAutoSettingsMessage: "BILL_disableAutoSettingsMessage",
  BILL_AutoSettingsErrorMessage: "BILL_AutoSettingsErrorMessage",
  BILL_refundSuccessNotification: "BILL_refundSuccessNotification",
  BILL_autoRenewalDisabled: "BILL_autoRenewalDisabled",
  BILL_offOptionTooltip: "BILL_offOptionTooltip",
  BILL_manualOptionTooltip: "BILL_manualOptionTooltip",
  BILL_smartOptionTooltip: "BILL_smartOptionTooltip",
  BILL_paymentSucceeded: "BILL_paymentSucceeded",
  BILL_paymentFailed: "BILL_paymentFailed",
  BILL_radioDisabledTooltipText: "BILL_radioDisabledTooltipText",
  BILL_topupDurationMessage: "BILL_topupDurationMessage",
  BILL_billingOptions: "BILL_billingOptions",
  BILL_fundNegativeBalance: "BILL_fundNegativeBalance",
  BILL_noRefundFunds: "BILL_noRefundFunds",
  BILL_noAddingFunds: "BILL_noAddingFunds",
  BILL_pendingBalanceBilling: "BILL_pendingBalanceBilling",
  BILL_refundAccount: "BILL_refundAccount",
  BILL_negativeBalance: "BILL_negativeBalance",
  BILL_negativeBalanceMessage: "BILL_negativeBalanceMessage",
  BILL_totalPendingCharge: "BILL_totalPendingCharge",
  BILL_addFundsProceed: "BILL_addFundsProceed",
  BILL_clickToManageFunds: "BILL_clickToManageFunds",
  BILL_billingInformation: "BILL_billingInformation",
  BILL_billingInfoName: "BILL_billingInfoName",
  BILL_billingInfoEmail: "BILL_billingInfoEmail",
  BILL_billingInfoAddress: "BILL_billingInfoAddress",
  BILL_billingInfoVatId: "BILL_billingInfoVatId",
  BILL_billingInfoEdit: "BILL_billingInfoEdit",
  BILL_invoices: "BILL_invoices",
  BILL_payInvoice: "BILL_payInvoice",
  BILL_invoiceViewMore: "BILL_invoiceViewMore",
  BILL_cloudApiPlan: "BILL_cloudApiPlan",
  BILL_partnerAccess: "BILL_partnerAccess",
  BILL_userInitiatedConversations: "BILL_userInitiatedConversations",
  BILL_withRegularSupport: "BILL_withRegularSupport",
  BILL_stripeBillingOptionsMissing: "BILL_stripeBillingOptionsMissing",
  BILL_requestRefund: "BILL_requestRefund",
  BILL_fundsAmount: "BILL_fundsAmount",
  BILL_fundsAmountDescription: "BILL_fundsAmountDescription",
  BILL_estimatedConversations: "BILL_estimatedConversations",
  BILL_selectedFundsAmount: "BILL_selectedFundsAmount",
  BILL_costBreakdownDescriptionOneTime: "BILL_costBreakdownDescriptionOneTime",
  BILL_costBreakdownDescriptionAutoRenewal:
    "BILL_costBreakdownDescriptionAutoRenewal",
  BILL_keepInMind: "BILL_keepInMind",
  BILL_keepInMindList1: "BILL_keepInMindList1",
  BILL_keepInMindList2: "BILL_keepInMindList2",
  BILL_breakdown: "BILL_breakdown",
  BILL_provideFundsAmount: "BILL_provideFundsAmount",
  BILL_allYourNumbersWillContinue: "BILL_allYourNumbersWillContinue",
  BILL_allNumbersThatWereUpgraded: "BILL_allNumbersThatWereUpgraded",
  BILL_total: "BILL_total",
  BILL_billingInformationDesc: "BILL_billingInformationDesc",
  BILL_checkAndDownloadInvoices: "BILL_checkAndDownloadInvoices",
  BILL_noInvoiceInfo: "BILL_noInvoiceInfo",
  BILL_currentBalance: "BILL_currentBalance",
  BILL_spentLastMonth: "BILL_spentLastMonth",
  BILL_fundsManager: "BILL_fundsManager",
  BILL_autoRenewalInfo: "BILL_autoRenewalInfo",
  BILL_setAutomaticFundingRenewal: "BILL_setAutomaticFundingRenewal",
  BILL_billingInfoUpdated: "BILL_billingInfoUpdated",
  BILL_noBillingInfoAddedInfo: "BILL_noBillingInfoAddedInfo",
  BILL_addBillingDetails: "BILL_addBillingDetails",
  BILL_paymentDetails: "BILL_paymentDetails",
  BILL_update: "BILL_update",
  BILL_expire: "BILL_expire",
  BILL_holder: "BILL_holder",
  BILL_noPaymentDetailsAddedInfo: "BILL_noPaymentDetailsAddedInfo",
  BILL_nextBillingInfo: "BILL_nextBillingInfo",
  BILL_billingDetails: "BILL_billingDetails",
  BILL_billingDetailsFormDesc: "BILL_billingDetailsFormDesc",
  BILL_payPendingInvoices: "BILL_payPendingInvoices",
  BILL_updateAutoRechargeTitle: "BILL_updateAutoRechargeTitle",
  BILL_updateAutoRechargeDesc: "BILL_updateAutoRechargeDesc",
  BILL_thresholdAmountErrorMessage: "BILL_thresholdAmountErrorMessage",
  BILL_autoRenewalAmountDesc: "BILL_autoRenewalAmountDesc",
  BILL_thresholdAmountDesc: "BILL_thresholdAmountDesc",
  BILL_paymentInformation: "BILL_paymentInformation",
  BILL_changeMethod: "BILL_changeMethod",
  BILL_addNewPaymentMethod: "BILL_addNewPaymentMethod",
  BILL_unpaidInvoice1: "BILL_unpaidInvoice1",
  BILL_unpaidInvoice2: "BILL_unpaidInvoice2",
  BILL_fundsAddedSuccessfully: "BILL_fundsAddedSuccessfully",
  BILL_autoRenewalAmountDescription: "BILL_autoRenewalDescription",
};

export default keys;
