// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.BILL_header]: "Bienvenido",
  [keys.BILL_balance]: "Balance",
  [keys.BILL_addFunds]: "Añadir fondos",
  [keys.BILL_addFundsPopup]: "Añadir fondos para template messages",
  [keys.BILL_addedFundsDescription]:
    "Los fondos añadidos sólo contarán para el consumo de template messages. Los planes mensuales o anuales para cuentas de WhatsApp Business API serán facturados y cobrados por separado.",
  [keys.BILL_addFundsPopupQuestion]:
    "¿Cuántas templates planea mandar por mes?",
  [keys.BILL_addFundsPopupTooltip]:
    "En base a su consumo planificado de templates podemos estimar los costes totales de sus template messages por período de facturación. No obstante, las tarifas de template messages varían según el país y por tanto los costes por mensaje son una mera estimación.",
  [keys.BILL_costBreakdown]: "Desglose de costes",
  [keys.BILL_estTemplateCost]: "Coste estimado de template messages",
  [keys.BILL_transFees]: "Cargo por transacción",
  [keys.BILL_subtotal]: "Subtotal",
  [keys.BILL_subAndPay]: "Enviar y pagar ahora",
  [keys.BILL_monthlyUsage]: "Uso mensual",
  [keys.BILL_numTemplatesSent]: "Número de templates enviadas",
  [keys.BILL_costsByTemplates]: "Costes causados por templates",
  [keys.BILL_noData]: "Sin datos para ese informe",
  [keys.BILL_filterByNum]: "Filtrar por número",
  [keys.BILL_selectAll]: "Seleccionar todos",
  [keys.BILL_numberNotLive]: "Este número aún no está activo",
  [keys.BILL_noBillingCountry]: "¡Usted necesita configurar el país de cobro!",
  [keys.BILL_amountDue]: "Total",
  [keys.BILL_notApplicable]: "N/A",
  [keys.BILL_vat]: "VAT",
  [keys.BILL_addFundsSectionMsg]:
    "La cantidad seleccionada arriba se cobrará y contará para su saldo actual para los costos de WhatsApp. Será un cargo único y no afectará a la configuración de renovación automática. Además, tenga en cuenta que las tarifas de suscripción mensual se cobran por separado y no utilizan el saldo prepago.",
  [keys.BILL_autoRenewalSuccess]:
    "Renovación automática habilitada con éxito. El umbral y la cantidad se pueden configurar manualmente.",
  [keys.BILL_thresholdChangeSuccess]:
    "Límite de la renovación automática se ha modificado con éxito",
  [keys.BILL_amountChangeSuccess]:
    "El importe de la renovación automática se ha modificado con éxito",
  [keys.BILL_renewalHeading]: "Administrar números",
  [keys.BILL_renewalTooltip]:
    "Si el saldo es inferior a este importe, el saldo se renueva con el importe de renovación automática.",
  [keys.BILL_thresholdSection]: "Límite para la renovación automática",
  [keys.BILL_excludingTransactionFees]:
    "sin incluir los gastos de transacción y el IVA",
  [keys.BILL_autoRenewal]: "Renovación automática",
  [keys.BILL_autoRenewalAmount]: "Valor de la renovación automática",
  [keys.BILL_oneOffCharge]: "Cargo único",
  [keys.BILL_changeSettings]: "Cambiar configuraciones",
  [keys.BILL_renewalPopupHeading]: "Cambiar valor de la renovación automática",
  [keys.BILL_renewalPopupSectionMsg]:
    "Esto cambia la configuración de su renovación automática. El importe seleccionado se cargará la próxima vez que su saldo sea inferior al valor del límite. No se le cobrará en este momento si el saldo sigue superior al límite.",
  [keys.BILL_submitChanges]: "Enviar cambios",
  [keys.BILL_balanceBreakDown]: "Detalles del saldo",
  [keys.BILL_selectNumberAction]: "Seleccionar número y acción",
  [keys.BILL_estimateCosts]: "Estimar costos",
  [keys.BILL_confirmCheckout]: "Confirmar checkout",
  [keys.BILL_selectAction]: "Seleccione cual acción quiere realizar",
  [keys.BILL_addOneOffFunds]: "Añadir fondos a este número con un cargo único",
  [keys.BILL_addAutorenewalSettings]:
    "Cambiar la configuración de la renovación automática de este número",
  [keys.BILL_autoRenewalSectionMsg]:
    "Al enviar los cambios, continúa modificando la configuración de su renovación automática. La cantidad seleccionada se cobrará la próxima vez que su saldo caiga por debajo del valor límite. No se le cobrará en este momento si su saldo aún está por encima del límite. Además, tenga en cuenta que las tarifas de suscripción mensual se cobran por separado y no utilizan el saldo prepago.",
  [keys.BILL_changeAutoRenewalThreshold]:
    "Modificar límite de renovación automática",
  [keys.BILL_thresholdAmount]: "Valor del límite de renovación automática",
  [keys.BILL_thresholdRenewalMsg]:
    "Si el saldo del número seleccionado es inferior al importe del límite de renovación automática, el saldo se renueva con el importe de renovación automática, que puede especificarse por separado.",
  [keys.BILL_processingPayment]: "desactivado",
  [keys.BILL_businessInitiatedQuestion]:
    "¿Cuántas conversaciones iniciadas por la empresa piensa tener?",
  [keys.BILL_userInitiatedQuestion]:
    "¿Cuántas conversaciones iniciadas por el usuario es probable que tenga?",
  [keys.BILL_estimatedConversationCost]: "Costos estimados de conversas",
  [keys.BILL_previousMonth]: "mes anterior",
  [keys.BILL_addFundsNotAllowed]:
    "No se pueden añadir fondos para números en esbozo",
  [keys.BILL_othersCombined]: "Otros (combinados)",
  [keys.BILL_approxCharges]: "Estimativas de cobro",
  [keys.BILL_allConversations]: "Todas las conversaciones",
  [keys.BILL_insights]: "Insights",
  [keys.BILL_insightsAndBilling]: "Insights & Billing",
  [keys.BILL_businessInitiatedAmountTooltip]:
    "Basándonos en la cantidad de conversaciones iniciadas por el negocio previstas, podemos estimar los costos de conversación que probablemente generará. Sin embargo, los precios de las conversaciones varían según el país y, por lo tanto, los costos por mensaje sólo pueden ser estimados. Estas estimaciones se basan en el supuesto de que usted sólo iniciará conversaciones dentro del país de origen de su número de teléfono registrado.",
  [keys.BILL_userInitiatedAmountTooltip]:
    "Basándonos en la cantidad de conversaciones iniciadas por el usuario previstas, podemos estimar los costos de conversación que probablemente generará. Sin embargo, los precios de las conversaciones varían según el país y, por lo tanto, los costos por mensaje sólo pueden ser estimados. Estas estimaciones se basan en la suposición de que sólo recibirá conversaciones iniciadas por el usuario dentro del país de origen de su número de teléfono registrado.",
  [keys.BILL_costBreakdownTooltip]:
    "Basándonos en la cantidad de conversaciones planificadas iniciadas por los usuarios y las empresas, podemos estimar los costos de las conversaciones que probablemente generará. Sin embargo, los precios de las conversaciones varían según el país y, por lo tanto, los costos por mensaje sólo pueden ser estimados. Estas estimaciones se basan en la suposición de que sólo tendrá conversaciones dentro del país de origen de su número de teléfono registrado.",
  [keys.BILL_customize]: "Personalizar",
  [keys.BILL_noCharges]:
    "No se cobrará nada; las conversaciones estimadas están dentro de sus conversaciones gratuitas mensuales disponibles (1000)",
  [keys.BILL_fundsWereAdded]: "Los fondos se añadieron con éxito",
  [keys.BILL_enabled]: "habilitado",
  [keys.BILL_disabled]: "desactivad",
  [keys.BILL_vat2]: "Agregar los fondos",
  [keys.BILL_zeroBalanceText_one]:
    "El saldo del número {{numbers}} es 0. Ya no puedes enviar mensajes dentro de una conversación pagada. Agregue fondos si desea continuar usando este número dentro de una conversación pagada.",
  [keys.BILL_zeroBalanceText_other]:
    "El saldo de estos números {{numbers}} es 0. Ya no puedes enviar mensajes dentro de una conversación pagada. Agregue fondos si desea continuar usando estos números dentro de una conversación pagada.",
  [keys.BILL_lowBalanceText_one]:
    "El saldo del número {{numbers}} es bajo. Para seguir participando en conversaciones pagadas, agregue fondos manualmente o habilite la renovación automática. Si el saldo de este número llega a 0, ya no podrá enviar mensajes dentro de una conversación pagada.",
  [keys.BILL_lowBalanceText_other]:
    "El saldo de estos números {{numbers}} es bajo. Para seguir participando en conversaciones pagadas, agregue fondos manualmente o habilite la renovación automática. Si el saldo de estos números llega a 0, ya no podrá enviar mensajes dentro de una conversación pagada.",
  [keys.BILL_automatic]: "Automático",
  [keys.BILL_manageFunds]: "Administrar fondos",
  [keys.BILL_refund]: "Reembolso",
  //  [keys.BILL_refundAccountTitle]: "translation_missing",
  [keys.BILL_refundingDetails]: "Detalles del rembolso",
  [keys.BILL_channelFunds]: "Fondos del canal",
  [keys.BILL_noPendingBalance]: "Sin saldo pendiente de reembolsar",
  [keys.BILL_totalAmountToRefund]: "Cantidad total de reembolso",
  [keys.BILL_refundInfomationText]:
    "Sus fondos serán reembolsados a través de su método de pago predeterminado.",
  //  [keys.BILL_refundInformationText2]: "translation_missing",
  [keys.BILL_fundsBalance]: "Saldo de fondos",
  //  [keys.BILL_automaticAutoRenewal]: "translation_missing",
  [keys.BILL_descriptionText1]:
    "¡Estamos felices de que estés usando nuestra nueva función!",
  [keys.BILL_descriptionText2]:
    "Hemos creado la renovación automática para ayudarte a gestionar tus fondos de una manera más eficiente.",
  [keys.BILL_descriptionText3]:
    "Basado en la cantidad de conversaciones de los meses anteriores y las tendencias en los costes, hemos configurando el siguiente límite y cantidad para su número:",
  [keys.BILL_threshold]: "Límite",
  [keys.BILL_autoMaticSettingMessage]:
    "Tenga en cuenta que estas configuraciones pueden variar para ajustarse siempre a los fondos requeridos para su numero.",
  [keys.BILL_setAutomaticRenewal]: "Establecer la renovación automática.",
  [keys.BILL_automaticTooltip]:
    "La renovación automática establece su límite y cantidad automáticamente en función de las conversaciones de los meses anteriores y las tendencias en los costos. Recomendamos tenerlo activo para nunca quedarse sin fondos y gestionarlos de forma precisa.",
  [keys.BILL_enableAutoSettingsMessage]:
    "Renovación automática inteligente habilitada con éxito. El umbral y la cantidad se calcularán automáticamente.",
  [keys.BILL_disableAutoSettingsMessage]:
    "Renovación automática desactivada con éxito.",
  [keys.BILL_AutoSettingsErrorMessage]:
    "No se pudo cambiar la configuración de renovación automática. Inténtalo de nuevo.",
  [keys.BILL_refundSuccessNotification]:
    "¡Su reembolso se ha iniciado con éxito! Su saldo se actualizará en breve.",
  [keys.BILL_autoRenewalDisabled]:
    "La renovación automática se desactivó correctamente",
  [keys.BILL_offOptionTooltip]:
    "Su saldo no se renovará automáticamente. Debe agregar fondos manualmente cada vez que su saldo se agote para continuar usando conversaciones pagas. Recomendamos habilitar la renovación automática para nunca quedarse sin fondos.",
  [keys.BILL_manualOptionTooltip]:
    "La renovación automática está activada y renovará automáticamente sus fondos en función de un límite y un monto de renovación que establezca manualmente a través de las opciones a la derecha.",
  [keys.BILL_smartOptionTooltip]:
    "La renovación automática está activada. Su umbral y monto se calculan y ajustan automáticamente en función de sus conversaciones del mes anterior y las tendencias en los costos",
  [keys.BILL_paymentSucceeded]: "Pago realizado con éxito",
  [keys.BILL_paymentFailed]:
    "El método de pago proporcionado no ha superado la autenticación. Proporcione un nuevo método de pago para intentar el pago nuevamente.",
  [keys.BILL_radioDisabledTooltipText]:
    'Para ajustar la configuración de renovación automática manualmente, debe cambiar la renovación automática al modo "manual".',
  [keys.BILL_topupDurationMessage]:
    "Puede tardar hasta 30 minutos en actualizarse su saldo despúes de haber realizado el pago. Para reducir el riesgo de que se bloqueen los mensajes, te recomendamos habilitar la renovación automática.",
  //  [keys.BILL_billingOptions]: "translation_missing",
  //  [keys.BILL_fundNegativeBalance]: "translation_missing",
  [keys.BILL_noRefundFunds]: "No hay fondos disponibles para reembolso",
  [keys.BILL_noAddingFunds]:
    "Por el momento este número no está disponible para agregar fondos",
  //  [keys.BILL_pendingBalanceBilling]: "translation_missing",
  //  [keys.BILL_refundAccount]: "translation_missing",
  //  [keys.BILL_negativeBalance]: "translation_missing",
  //  [keys.BILL_negativeBalanceMessage]: "translation_missing",
  //  [keys.BILL_totalPendingCharge]: "translation_missing",
  //  [keys.BILL_addFundsProceed]: "translation_missing",
  //  [keys.BILL_clickToManageFunds]: "translation_missing",
  //  [keys.BILL_billingInformation]: "translation_missing",
  //  [keys.BILL_billingInfoName]: "translation_missing",
  //  [keys.BILL_billingInfoEmail]: "translation_missing",
  //  [keys.BILL_billingInfoAddress]: "translation_missing",
  //  [keys.BILL_billingInfoVatId]: "translation_missing",
  //  [keys.BILL_billingInfoEdit]: "translation_missing",
  //  [keys.BILL_invoices]: "translation_missing",
  //  [keys.BILL_payInvoice]: "translation_missing",
  //  [keys.BILL_invoiceViewMore]: "translation_missing",
  //  [keys.BILL_cloudApiPlan]: "translation_missing",
  //  [keys.BILL_partnerAccess]: "translation_missing",
  //  [keys.BILL_userInitiatedConversations]: "translation_missing",
  //  [keys.BILL_withRegularSupport]: "translation_missing",
  //  [keys.BILL_stripeBillingOptionsMissing]: "translation_missing",
  //  [keys.BILL_requestRefund]: "translation_missing",
  //  [keys.BILL_fundsAmount]: "translation_missing",
  //  [keys.BILL_fundsAmountDescription]: "translation_missing",
  //  [keys.BILL_estimatedConversations]: "translation_missing",
  //  [keys.BILL_selectedFundsAmount]: "translation_missing",
  //  [keys.BILL_costBreakdownDescriptionOneTime]: "translation_missing",
  //  [keys.BILL_costBreakdownDescriptionAutoRenewal]: "translation_missing",
  //  [keys.BILL_keepInMind]: "translation_missing",
  //  [keys.BILL_keepInMindList1]: "translation_missing",
  //  [keys.BILL_keepInMindList2]: "translation_missing",
  //  [keys.BILL_breakdown]: "translation_missing",
  //  [keys.BILL_provideFundsAmount]: "translation_missing",
  //  [keys.BILL_allYourNumbersWillContinue]: "translation_missing",
  //  [keys.BILL_allNumbersThatWereUpgraded]: "translation_missing",
  [keys.BILL_total]: "Total",
  //  [keys.BILL_billingInformationDesc]: "translation_missing",
  //  [keys.BILL_checkAndDownloadInvoices]: "translation_missing",
  //  [keys.BILL_noInvoiceInfo]: "translation_missing",
  //  [keys.BILL_currentBalance]: "translation_missing",
  //  [keys.BILL_spentLastMonth]: "translation_missing",
  //  [keys.BILL_fundsManager]: "translation_missing",
  //  [keys.BILL_autoRenewalInfo]: "translation_missing",
  //  [keys.BILL_setAutomaticFundingRenewal]: "translation_missing",
  //  [keys.BILL_billingInfoUpdated]: "translation_missing",
  //  [keys.BILL_noBillingInfoAddedInfo]: "translation_missing",
  //  [keys.BILL_addBillingDetails]: "translation_missing",
  //  [keys.BILL_paymentDetails]: "translation_missing",
  //  [keys.BILL_update]: "translation_missing",
  //  [keys.BILL_expire]: "translation_missing",
  //  [keys.BILL_holder]: "translation_missing",
  //  [keys.BILL_noPaymentDetailsAddedInfo]: "translation_missing",
  //  [keys.BILL_nextBillingInfo]: "translation_missing",
  //  [keys.BILL_billingDetails]: "translation_missing",
  //  [keys.BILL_billingDetailsFormDesc]: "translation_missing",
  //  [keys.BILL_payPendingInvoices]: "translation_missing",
  //  [keys.BILL_updateAutoRechargeTitle]: "translation_missing",
  //  [keys.BILL_updateAutoRechargeDesc]: "translation_missing",
  //  [keys.BILL_thresholdAmountErrorMessage]: "translation_missing",
  //  [keys.BILL_autoRenewalAmountDesc]: "translation_missing",
  //  [keys.BILL_thresholdAmountDesc]: "translation_missing",
  //  [keys.BILL_paymentInformation]: "translation_missing",
  //  [keys.BILL_changeMethod]: "translation_missing",
  //  [keys.BILL_addNewPaymentMethod]: "translation_missing",
  //  [keys.BILL_radioDisabledTooltipText]: "translation_missing",
  //  [keys.BILL_fundNegativeBalance]: "translation_missing",
  //  [keys.BILL_unpaidInvoice1]: "translation_missing",
  //  [keys.BILL_unpaidInvoice2]: "translation_missing",
};

export default translate;
