import * as React from "react";
import * as Styled from "./StatusStyles";
import { Body } from "@gcp-artifact/compass/lib/components";

export interface IStatusCircle extends React.HTMLAttributes<HTMLElement> {
  className?: string;
  status: string;
  statusMapper?: (props: { status: string }) => string;
  hideText?: boolean;
  withoutMargins?: boolean;
  children?: React.ReactNode;
  tooltip?: boolean;
  textTransform?: string;
  loading?: boolean;
  variant?: "default" | "rectangular";
  padding?: string;
  fullVertical?: boolean;
  width?: string;
  borderRadius?: string;
}

const Status = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  className,
  status = "neutral",
  hideText,
  withoutMargins,
  children,
  statusMapper,
  tooltip = false,
  textTransform,
  loading,
  variant = "default",
  padding,
  fullVertical,
  width,
  borderRadius,
  ...props
}: IStatusCircle) => {
  if (variant === "rectangular") {
    return (
      <Styled.StatusFieldWrapper fullVertical={fullVertical} {...props}>
        <Styled.StatusField
          $loading={loading}
          status={status}
          statusMapper={statusMapper}
          padding={padding}
          width={width}
          borderRadius={borderRadius}
        >
          {!hideText && children ? (
            loading ? (
              <Styled.StatusLoadingSkeleton />
            ) : (
              <Body size="xs" withoutMargins style={{ textAlign: "center" }}>
                {children}
              </Body>
            )
          ) : (
            !hideText &&
            (loading ? (
              <Styled.StatusLoadingSkeleton />
            ) : (
              <Body size="xs" withoutMargins>
                {status}
              </Body>
            ))
          )}
        </Styled.StatusField>
      </Styled.StatusFieldWrapper>
    );
  } else {
    return (
      <Styled.StatusCircleContainer withoutMargins={withoutMargins} {...props}>
        <Styled.StatusCircle
          tooltip
          status={status}
          statusMapper={statusMapper}
          $loading={loading}
        />
        {tooltip && (
          <Styled.TooltipBox>
            <p style={{ margin: 0 }}>{status.toUpperCase()}</p>
          </Styled.TooltipBox>
        )}
        {!hideText && children ? (
          loading ? (
            <Styled.StatusLoadingSkeleton />
          ) : (
            <Styled.StatusCircleText>
              {typeof children === "string" ? children.toUpperCase() : children}
            </Styled.StatusCircleText>
          )
        ) : (
          !hideText &&
          (loading ? (
            <Styled.StatusLoadingSkeleton />
          ) : (
            <Styled.StatusCircleText textTransform={textTransform}>
              {status}
            </Styled.StatusCircleText>
          ))
        )}
      </Styled.StatusCircleContainer>
    );
  }
};

export default Status;
