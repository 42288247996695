// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.INS_understandWhatWorksBest]: "translation_missing",
  //  [keys.INS_trackedConversion]: "translation_missing",
  //  [keys.INS_conversationsFrom]: "translation_missing",
  //  [keys.INS_whereLeads]: "translation_missing",
  //  [keys.INS_editConversions]: "translation_missing",
  //  [keys.INS_edit]: "translation_missing",
  //  [keys.INS_allSources]: "translation_missing",
  //  [keys.INS_incoming]: "translation_missing",
  //  [keys.INS_outgoing]: "translation_missing",
  //  [keys.INS_organic]: "translation_missing",
  //  [keys.INS_sourceName]: "translation_missing",
  //  [keys.INS_sourceBreakdown]: "translation_missing",
  //  [keys.INS_startedConversations]: "translation_missing",
  //  [keys.INS_conversionRate]: "translation_missing",
  //  [keys.INS_convertedLeads]: "translation_missing",
  //  [keys.INS_invested]: "translation_missing",
  //  [keys.INS_revenue]: "translation_missing",
  //  [keys.INS_downloadLeads]: "translation_missing",
  //  [keys.INS_searchNumber]: "translation_missing",
  //  [keys.INS_downloadAllLeads]: "translation_missing",
  // [keys.INS_saveEvent]: "translation_missing",
  // [keys.INS_saveEventSubtitle]: "translation_missing",
  // [keys.INS_notificationEventCreated]: "translation_missing",
  // [keys.INS_saveEventTooltip]: "translation_missing",
  // [keys.INS_writeTheEvent]: "translation_missing",
  // [keys.INS_saveEventLabel]: "translation_missing",
  // [keys.INS_confirm]: "translation_missing",
  // [keys.INS_downloadAllLeadsDescription]: "translation_missing",
  //  [keys.INS_leadsDescription]: "translation_missing",
  //  [keys.INS_percentageLeads]: "translation_missing",
  //  [keys.INS_leadsConversion]: "translation_missing",
  //  [keys.INS_investedDescription]: "translation_missing",
  //  [keys.INS_conversionCost]: "translation_missing",
  //  [keys.INS_revenueDescription]: "translation_missing",
  //  [keys.INS_returnDescription]: "translation_missing",
  // [keys.INS_impressionsTitle]: "translation_missing",
  // [keys.INS_impressionsDescription]: "translation_missing",
  // [keys.INS_paidClicksTitle]: "translation_missing",
  // [keys.INS_paidClicksDescription]: "translation_missing",
  //  [keys.INS_roasDescription]: "translation_missing",
  //  [keys.INS_learnEnable]: "translation_missing",
  //  [keys.INS_costPerConversion]: "translation_missing",
  //  [keys.INS_revenueNotAvailable]: "translation_missing",
  //  [keys.INS_adsFrom]: "translation_missing",
  //  [keys.INS_sourceInfo]: "translation_missing",
  //  [keys.INS_detailedInfo]: "translation_missing",
  //  [keys.INS_typeLabel]: "translation_missing",
  //  [keys.INS_typeInfo]: "translation_missing",
  //  [keys.INS_typeDescription]: "translation_missing",
  //  [keys.INS_incomingCustom]: "translation_missing",
  //  [keys.INS_incomingCustomDescription]: "translation_missing",
  //  [keys.INS_giveThisSourceName]: "translation_missing",
  //  [keys.INS_yesterday]: "translation_missing",
  //  [keys.INS_enableIt]: "translation_missing",
  //  [keys.INS_steps]: "translation_missing",
  //  [keys.INS_createNewFunnel]: "translation_missing",
  //  [keys.INS_nameFunnel]: "translation_missing",
  //  [keys.INS_replaceEvent]: "translation_missing",
  //  [keys.INS_selectEvent]: "translation_missing",
  //  [keys.INS_removeEvent]: "translation_missing",
  //  [keys.INS_mustNotExceed20]: "translation_missing",
  //  [keys.INS_costPerAcquisition]: "translation_missing",
  //  [keys.INS_inThreadEvent]: "translation_missing",
  //  [keys.INS_offThreadEvent]: "translation_missing",
  //  [keys.INS_funnelUpdated]: "translation_missing",
  //  [keys.INS_sourceTypes]: "translation_missing",
  // [keys.INS_sources]: "translation_missing",
  // [keys.INS_sourcesDescription]: "translation_missing",
  // [keys.INS_messages]: "translation_missing",
  // [keys.INS_folders]: "translation_missing",
  // [keys.INS_ads]: "translation_missing",
  //  [keys.INS_metaClickToWhatsApp]: "translation_missing",
  //  [keys.INS_metaCTWA]: "translation_missing",
  //  [keys.INS_incomingCampaigns]: "translation_missing",
  //  [keys.INS_outgoingTemplates]: "translation_missing",
  //  [keys.INS_open]: "translation_missing",
  //  [keys.INS_custom]: "translation_missing",
  //  [keys.INS_lastWeek]: "translation_missing",
  //  [keys.INS_last2Weeks]: "translation_missing",
  //  [keys.INS_lastMonth]: "translation_missing",
  //  [keys.INS_cancel]: "translation_missing",
  //  [keys.INS_apply]: "translation_missing",
  //  [keys.INS_selectEventToViewDetails]: "translation_missing",
  // [keys.INS_bestRoas]: "translation_missing",
  // [keys.INS_bestRoasTooltip]: "translation_missing",
  // [keys.INS_bestRoasTooltipLink]: "translation_missing",
  // [keys.INS_worstRoas]: "translation_missing",
  // [keys.INS_worstRoasTooltip]: "translation_missing",
  // [keys.INS_worstRoasTooltipLink]: "translation_missing",
  // [keys.INS_orderingFilter]: "translation_missing",
  // [keys.INS_details]: "translation_missing",
};

export default translate;
