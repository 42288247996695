// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  FN_intro: "FN_intro",
  FN_createAndSaveFunnel: "FN_createAndSaveFunnel",
  FN_organizeSource: "FN_organizeSource",
  FN_optimizeCampaigns: "FN_optimizeCampaigns",
  FN_introFn: "FN_introFn",
  FN_introBodyFirst: "FN_introBodyFirst",
  FN_introBodySecond: "FN_introBodySecond",
  FN_introCTA: "FN_introCTA",
  FN_creationNotAvailable: "FN_creationNotAvailable",
  FN_orderingOptionChanged: "FN_orderingOptionChanged",
  FN_updated: "FN_updated",
  FN_created: "FN_created",
  FN_renamed: "FN_renamed",
};

export default keys;
