import * as React from "react";
import * as Styled from "./TextButtonStyles";

export interface ITextButton {
  variant?: "primary" | "secondary";
  children: string | React.ReactNode;
  backgroundColor?: string;
  inline?: boolean;
  size?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  showUnderline?: boolean;
}

const TextButton = ({
  children,
  variant = "secondary",
  backgroundColor,
  inline,
  size,
  onClick,
  disabled,
  showUnderline = true,
}: ITextButton) => {
  return (
    <Styled.TextButton
      onClick={onClick}
      variant={variant}
      backgroundColor={backgroundColor}
      disabled={disabled}
      inline={inline}
      size={size}
      showUnderline={showUnderline}
    >
      {children}
    </Styled.TextButton>
  );
};

export default TextButton;
