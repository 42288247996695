import { MPInstanceTos } from "@/services/api/RequestAxios/RequestAxiosToS";
import * as Types from "./tos.types";

const acceptanceUrl = ({ tos_name, entity_id }) =>
  `/tos_types/${tos_name}/entity/${entity_id}/acceptance`;

const latestTosVersionUrl = ({ tos_name }) =>
  `/tos_types/${tos_name}/versions/latest`;

const tosDocByRevisionUrl = ({ tos_name, revision }) =>
  `/tos_types/${tos_name}/versions/${revision}/document`;

const acceptTosUrl = ({ tos_name, revision }) =>
  `/tos_types/${tos_name}/versions/${revision}/acceptance`;

const defaultTosName = "Client Terms of Service";

export const getAcceptance = async ({ entityId }: { entityId: string }) => {
  const { data } = await MPInstanceTos.buffedInstances.get(
    acceptanceUrl({
      tos_name: defaultTosName,
      entity_id: entityId,
    })
  );
  return data;
};

export const getLatestTosVersion = async () => {
  const { data } =
    await MPInstanceTos.buffedInstances.get<Types.IServerTermsOfService>(
      latestTosVersionUrl({
        tos_name: defaultTosName,
      })
    );
  return data;
};

export const getTosDocByRevision = async ({
  revision,
}: {
  revision: number;
}) => {
  const { data } = await MPInstanceTos.buffedInstances.get(
    tosDocByRevisionUrl({ tos_name: defaultTosName, revision }),
    {
      responseType: "blob",
    }
  );
  return data;
};

export const acceptTos = async ({
  revision,
  entityId,
}: {
  revision: number;
  entityId: string;
}) => {
  const { data } = await MPInstanceTos.buffedInstances.post(
    acceptTosUrl({ tos_name: defaultTosName, revision }),
    {
      entity_id: entityId,
    }
  );
  return data;
};
