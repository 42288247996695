import styled, { css, keyframes } from "styled-components";
import translate from "@/utilities/i18n/getTranslate";
import { k } from "@/utilities/i18n";

const getColorByStatus = (props: { status: string }) => {
  switch (props.status) {
    case "success": {
      return "var(--success)";
    }
    case "done": {
      return "var(--success)";
    }
    case "live": {
      return "var(--success)";
    }
    case "pending": {
      return "var(--warning)";
    }
    case "error": {
      return "var(--danger)";
    }
    case "rejected": {
      return "var(--danger)";
    }
    case "unregistered": {
      return "var(--danger)";
    }
    case "flagged": {
      return "var(--danger)";
    }
    // due to inconsistencies with backend response
    // i added this extra check just in case
    case "Channel inactive":
    case "channel inactive":
    case translate(k.CH_deletedChannel): {
      return "var(--danger)";
    }
    default: {
      return "var(--concrete)";
    }
  }
};

export const StatusCircleContainer = styled.div.attrs({
  className: "StatusCircleContainer",
})<{ withoutMargins?: boolean }>`
  position: relative;
  display: grid;
  grid-column-gap: 0.8rem;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  ${(props) => (props.withoutMargins ? "" : "padding: 0.8rem;")}
`;

export const StatusCircleText = styled.div.attrs({
  className: "StatusCircleText",
})<{ textTransform?: string }>`
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--steel);
  text-transform: ${(props) => props.textTransform || "uppercase"};
`;

const loadingAnimation = keyframes`
  0% {
    transform: skewX(-10deg) translateX(-100%);
  }
  100% {
    transform: skewX(-10deg) translateX(200%);
  }
`;

export const StatusLoadingSkeleton = styled.div.attrs({
  className: "StatusLoadingSkeleton",
})`
  height: var(--skeleton-height, 1.4rem);
  width: var(--skeleton-width, 13.6rem);
  border-radius: var(--skeleton-border-radius, 0.2rem);
  background: var(--snow-dark);
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    background: linear-gradient(
      90deg,
      transparent,
      var(--snow-darker),
      transparent
    );
    width: 80%;
    height: 100%;
    top: 0;
    left: 0;
    animation: ${loadingAnimation} 1.2s infinite;
  }
`;

export const TooltipBox = styled.div`
  position: absolute;
  top: 0;
  right: calc(100% + 0.5rem);
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  min-width: 5rem;
  white-space: nowrap;
  padding: 0.4rem;
  border-radius: 0.6rem;
  transition: visibility 0.3s, color 0.3s, background-color 0.3s, width 0.3s,
    padding 0.3s ease-in-out;
  &:after {
    opacity: 0;
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -0.5rem;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.8);
    transition: opacity 0.3s, color 0.3s, border 0.3s, border-color 0.3s,
      width 0.3s, padding 0.3s ease-in-out;
  }
`;

export const StatusCircle = styled.div.attrs({
  className: "StatusCircle",
})<{
  status: string;
  statusMapper?: (props: { status: string }) => string;
  tooltip: boolean;
  $loading?: boolean;
}>`
  width: 1.6rem;
  height: 1.6rem;
  border-width: 4px;
  border-style: solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: ${(props) =>
    props.statusMapper ? props.statusMapper : getColorByStatus};
  ${(props) =>
    props.$loading &&
    css`
      border-color: var(--snow-dark);
    `}
  &:hover + ${TooltipBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    min-width: 5rem;
    padding: 0.8rem;
    &:after {
      opacity: 1;
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -0.4rem;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent rgba(0, 0, 0, 0.8);
    }
  }
`;

export const StatusFieldWrapper = styled.div.attrs({
  className: "StatusFieldWrapper",
})<{ fullVertical: boolean }>`
  display: flex;
  height: ${(props) => props.fullVertical && "100%"};
`;

const mapMainColorToBgColor = (colorVar: string) => {
  switch (colorVar) {
    case "var(--success)": {
      return "rgba(32, 175, 90, 0.2)";
    }
    case "var(--warning)": {
      return "rgba(255, 199, 89, 0.12)";
    }
    case "var(--danger)": {
      return "rgba(245, 0, 50, 0.15)";
    }
    default: {
      return "var(--snow-darker)";
    }
  }
};

const mapMainColorToTextColor = (colorVar: string) => {
  switch (colorVar) {
    case "var(--success)": {
      return "var(--green-dark)";
    }
    case "var(--warning)": {
      return "var(--warning)";
    }
    case "var(--danger)": {
      return "var(--danger-dark)";
    }
    default: {
      return "var(--steel)";
    }
  }
};

export const StatusField = styled.div.attrs({
  className: "StatusField",
})<{
  statusMapper?: (props: { status: string }) => string;
  status: string;
  $loading?: boolean;
  padding?: string;
  width?: string;
  borderRadius?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.statusMapper
      ? mapMainColorToBgColor(props.statusMapper({ status: props.status }))
      : mapMainColorToBgColor(getColorByStatus({ status: props.status }))};
  padding: ${(props) =>
    props.padding ? props.padding : !props.$loading ? "0px 0.8rem" : "0px"};
  width: ${(props) => (props.width ? props.width : "auto")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "0.2rem"};
  .Body {
    color: ${(props) =>
      props.statusMapper
        ? mapMainColorToTextColor(props.statusMapper({ status: props.status }))
        : mapMainColorToTextColor(getColorByStatus({ status: props.status }))};
  }
`;
